<template>
  <Card :class="{ sidebar: true, isVisible: isVisible }">
    <Button class="toogle" :click="toogle" title="open">
      <MenuIcon v-if="!isVisible" />
      <MenuOpenIcon v-if="isVisible" />
    </Button>
    <nav>
      <Link
        v-for="(item, key) in items"
        :key="key"
        :name="item.name"
        :to="item.to"
        :href="item.href"
        :icon="item.icon"
        :click="hide"
      />
    </nav>
  </Card>
</template>

<script>
import { markRaw } from 'vue';
import MenuIcon from 'vue-material-design-icons/Menu.vue';
import MenuOpenIcon from 'vue-material-design-icons/MenuOpen.vue';
import HelpIcon from 'vue-material-design-icons/HelpCircle.vue';
import DashboardIcon from 'vue-material-design-icons/ViewDashboard.vue';
import LinkIcon from 'vue-material-design-icons/Link.vue';
import AppsBoxIcon from 'vue-material-design-icons/AppsBox.vue';
import ContactsIcon from 'vue-material-design-icons/Contacts.vue';
import Link from '@/components/Link';

export default {
  name: 'Sidebar',
  data() {
    return {
      isVisible: false,
      items: [
        { name: 'Dashboard', to: '/', icon: markRaw(DashboardIcon) },
        {
          name: 'How to connect',
          to: '/how-to-connect',
          icon: markRaw(LinkIcon),
        },
        { name: 'Apps', to: '/apps', icon: markRaw(AppsBoxIcon) },
        { name: 'Support', to: '/apps', icon: markRaw(ContactsIcon) },
        {
          name: 'Faq',
          href: 'https://www.fastblockchains.com/#faq',
          icon: markRaw(HelpIcon),
        },
      ],
    };
  },
  methods: {
    toogle() {
      this.isVisible = !this.isVisible;
    },
    hide() {
      this.isVisible = false;
    },
  },
  components: { MenuIcon, MenuOpenIcon, Link },
};
</script>

<style scoped lang="scss">
.card.sidebar {
  background: white;
  justify-content: start;
  transition: $duration transform, $duration box-shadow;
  height: 100%;
  z-index: 1;
  nav {
    display: grid;
    gap: $space;
    grid-auto-flow: row;
    align-content: start;
    align-items: center;
    justify-content: start;
    place-content: stretch;
    overflow: auto;
    width: 100%;
    a {
      height: 100%;
      display: flex;
      gap: $padding;
      align-items: center;
      cursor: pointer;
      text-decoration: none;
      font-weight: bold;
      color: $colorPrimary;
      :deep(.material-design-icon) {
        display: flex;
        &.open-in-new-icon {
          margin: 0 0 0 auto;
        }
      }
      &.router-link-exact-active {
        color: $colorActive;
      }
    }
  }
  button.toogle {
    display: none;
  }

  @media (max-width: $screenMd) {
    position: fixed;
    top: $buttonHeight;
    left: 0;
    width: calc(100vw - #{$buttonHeight});
    height: calc(100vh - #{$buttonHeight});
    transform: translateX(-100%);
    box-shadow: none;
    border-radius: 0;
    &.isVisible {
      transform: translateX(0);
      box-shadow: 0 0 ($padding * 3) rgba(0, 0, 0, 0.3);
    }
    button.toogle {
      display: flex;
      position: absolute;
      top: $space;
      left: 100%;
      height: 40px;
      width: 40px;
      background: white;
      border-radius: 0 $padding $padding 0;
      box-shadow: 0 0 ($padding * 3) rgba(0, 0, 0, 0.3);
      border: 0;
      cursor: pointer;
      &:after {
        content: '';
        position: absolute;
        top: -10;
        width: 40px;
        height: 60px;
        background: white;
        left: -100%;
      }
    }
  }
}
</style>
