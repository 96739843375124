<template>
  <div class="loading"></div>
</template>

<script>
export default {
  data() {
    return { loading: false };
  },
};
</script>

<style lang="scss" scoped>
.loading {
  border: ($space * 2) solid #f3f3f3;
  border-top: ($space * 2) solid $colorActive;
  border-radius: 50%;
  width: ($space * 2);
  height: ($space * 2);
  animation: spin ($duration * 4) linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
