<template>
  <Transition>
    <component :is="is" :class="{ card: true, isLoading }" v-if="show">
      <Loading v-if="isLoading" />
      <template v-if="!isLoading">
        <slot />
        <Button
          class="close"
          title="close"
          v-if="showButtonClose"
          :click="() => (this.show = false)"
        >
          <CloseIcon />
        </Button>
      </template>
    </component>
  </Transition>
</template>

<script>
import CloseIcon from 'vue-material-design-icons/Close.vue';

export default {
  name: 'Card',
  props: {
    is: {
      type: String,
      default: 'div',
    },
    showButtonClose: {
      type: Boolean,
      default: false,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return { show: true };
  },
  components: { CloseIcon },
};
</script>

<style scoped lang="scss">
.card {
  position: relative;
  display: flex;
  flex-flow: column;
  gap: $padding * 2;
  align-items: flex-start;
  justify-content: center;
  border-radius: 0.75rem;
  padding: $space;
  box-shadow: 0 0 ($padding * 3) rgba(0, 0, 0, 0.3);
  .close {
    position: absolute;
    top: $padding * 2;
    right: $space;
    border-radius: 50%;
    width: 40px;
    height: 40px;
  }

  &.disabled {
    &:after {
      content: '';
      position: absolute;
      background: black;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border-radius: $padding;
      opacity: 0.3;
      cursor: not-allowed;
    }
  }
  &.isLoading {
    min-height: 150px;
    align-items: center;
  }
}

.v-enter-active,
.v-leave-active {
  transition: opacity $duration;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
</style>
