import { toast } from '@/lib/toast';
import { getAuthHeader } from '@/api/firebase';

const fetchjs = async function (url, options = {}, authenticate = true) {
  try {
    options.headers = {
      ...{ 'Content-Type': 'application/json' },
      ...options.headers,
    };

    if (authenticate) {
      options.headers = { ...options.headers, ...(await getAuthHeader()) };
    }

    const response = await fetch(url, options);
    if (!response.ok) {
      throw new Error();
    }
    return await response.json();
  } catch (error) {
    toast(`Ops! Something went wrong`, 'error', error);
  }
};

export { fetchjs };
