<template>
  <header>
    <Logo theme="light" />
    <nav>
      <router-link to="/prices">Prices</router-link>
      <Dropdown :items="getMenuItems" :showArrow="false">
        <AccountIcon />
        <img
          v-if="me.picture"
          :src="me.picture"
          :alt="`${me.firstName} ${me.lastName}`"
          referrerpolicy="no-referrer"
        />
      </Dropdown>
    </nav>
  </header>
</template>

<script>
import { storeToRefs } from 'pinia';
import AccountIcon from 'vue-material-design-icons/Account.vue';
import { useMeStore } from '@/stores/me';
import Logo from '@/components/Logo';
import Dropdown from '@/components/Dropdown';

export default {
  name: 'Header',
  setup() {
    const store = useMeStore();
    const { isAuth, me } = storeToRefs(store);
    return { isAuth, me, store };
  },
  methods: {
    logout() {
      this.store.logout();
    },
  },
  computed: {
    getMenuItems() {
      if (this.isAuth) {
        return [
          { name: 'Profile', click: () => this.$router.push('/profile') },
          { name: 'Logout', click: () => this.logout() },
        ];
      }

      return [{ name: 'Login', click: () => this.$router.push('/login') }];
    },
    cssPicture() {
      return `background-image: url(${this.me.picture})`;
    },
  },
  components: { Logo, Dropdown, AccountIcon },
};
</script>

<style scoped lang="scss">
header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 2;
  background: $colorPrimary;
  height: $buttonHeight;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px $colorPrimary solid;
  padding: 0 $space;
  nav {
    display: grid;
    grid-auto-flow: column;
    align-items: center;
    gap: $padding * 2;
    margin: 0 0 0 auto;
    a {
      height: 100%;
      display: flex;
      align-items: center;
      cursor: pointer;
      text-decoration: none;
      font-weight: bold;
      color: white;
      &.router-link-exact-active {
        color: $colorActive;
      }
    }
    :deep(.dropdown) {
      button {
        position: relative;
        border: 0;
        background-color: $colorActive;
        border-radius: 50%;
        color: white;
        height: 40px;
        width: 40px;
        overflow: hidden;
        &:hover,
        &:active,
        &:focus {
          background: lighten($colorActive, 10%);
        }
        > img {
          position: absolute;
          width: 100%;
          height: 100%;
          border-radius: 50%;
          object-fit: contain;
        }
      }
      @media (max-width: $screenMd) {
        position: unset;
        ul.card {
          position: fixed;
          justify-content: start;
          top: $buttonHeight;
          left: 0;
          right: 0;
          bottom: 0;
          border-radius: 0;
          opacity: 1;
          transform: translate(100%);
          transition: $duration transform;
          li {
            background-color: white;
            border-bottom: 1px $colorBorder solid;
          }
        }
        &.isVisible ul.card {
          transform: translate(0);
        }
      }
    }
  }
}
</style>
