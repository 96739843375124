<template>
  <Header />
  <main :class="{ hasSidebar }">
    <Sidebar v-if="hasSidebar" />
    <router-view />
  </main>
  <Footer />
</template>

<script setup>
onMounted(() => {
  useMeStore().checkLogin();
});
</script>

<script>
import { onMounted } from 'vue';
import { useMeStore } from '@/stores/me';
import Header from '@/components/Header';
import Footer from '@/components/Footer';
import Sidebar from '@/components/Sidebar';

export default {
  components: { Header, Footer, Sidebar },
  data() {
    return {
      hasSidebar: false,
    };
  },
  watch: {
    routeMeta: {
      handler() {
        if (this.$route.meta && this.$route.meta.hasSidebar === true) {
          this.hasSidebar = true;
        } else {
          this.hasSidebar = false;
        }
      },
      immediate: true,
    },
  },
  computed: {
    routeMeta() {
      return this.$route.meta;
    },
  },
};
</script>

<style lang="scss">
@import '@/styles/main.scss';
</style>
