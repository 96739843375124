<template>
  <button
    :type="type"
    :class="{ [theme]: true, isLoading: isLoading }"
    :disabled="disabled"
    :title="myTitle"
    :aria-label="myTitle"
    @click="click"
  >
    <slot />
    {{ name }}
    <Loading v-if="isLoading" />
  </button>
</template>

<script>
export default {
  name: 'Button',
  props: {
    name: String,
    title: String,
    click: {
      type: Function,
      default: () => {},
    },
    type: String,
    isDisabled: {
      type: Boolean,
      default: false,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    theme: {
      type: String,
      validator(value) {
        return ['info', 'primary', 'success', 'warning', 'danger'].includes(
          value
        );
      },
      default() {
        return 'info';
      },
    },
  },
  computed: {
    myTitle() {
      return this.title || this.name;
    },
  },
  watch: {
    isLoading: {
      handler() {
        this.disabled = this.isLoading ? true : this.isDisabled;
      },
      immediate: true,
    },
  },
};
</script>

<style scoped lang="scss">
button {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: $padding;
  height: $buttonHeight;
  font-size: large;
  color: white;
  font-weight: 900;
  border: 0;
  border-radius: $padding;
  transition: background $duration, color $duration;
  padding: 0 $space;
  cursor: pointer;

  :deep(.material-design-icon) {
    display: flex;
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
  &.isLoading {
    cursor: wait;
    .loading {
      margin: 0 0 0 $padding;
      border-width: $space;
    }
  }

  @include theme('info', white);
  @include theme('primary', $colorPrimary);
  @include theme('success', $colorSuccess);
  @include theme('warning', $colorWarning);
  @include theme('danger', $colorDanger, true, null, 5%);

  &.info {
    color: $colorPrimary;
    border: 1px $colorPrimary solid;
    @include themeHover(#f3f3f3);
  }
}
</style>
