import { createRouter, createWebHistory } from 'vue-router';
import { useMeStore } from '@/stores/me';

const routes = [
  {
    path: '/',
    name: 'Dashboard',
    component: () =>
      import(/* webpackChunkName: 'dashboard' */ '@/views/Dashboard.vue'),
    meta: { requiresAuth: true, hasSidebar: true },
  },
  {
    path: '/how-to-connect',
    name: 'How to connect',
    component: () =>
      import(/* webpackChunkName: 'howToConnect' */ '@/views/HowToConnect.vue'),
    meta: { requiresAuth: true, hasSidebar: true },
  },
  {
    path: '/apps',
    name: 'Apps',
    component: () => import(/* webpackChunkName: 'apps' */ '@/views/Apps.vue'),
    meta: { requiresAuth: true, hasSidebar: true },
  },
  {
    path: '/about',
    name: 'About',
    component: () =>
      import(/* webpackChunkName: 'about' */ '@/views/About.vue'),
  },
  {
    path: '/login',
    name: 'Login',
    component: () =>
      import(/* webpackChunkName: 'login' */ '@/views/Login.vue'),
  },
  {
    path: '/profile',
    name: 'Profile',
    component: () =>
      import(/* webpackChunkName: 'profile' */ '@/views/Profile.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '/prices',
    name: 'Prices',
    component: () =>
      import(/* webpackChunkName: 'prices' */ '@/views/Prices.vue'),
  },
  {
    path: '/buy/:slug',
    name: 'Buy Tier ${slug}',
    component: () => import(/* webpackChunkName: 'buy' */ '@/views/Buy.vue'),
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'Page not found',
    component: () =>
      import(/* webpackChunkName: 'notFound' */ '@/views/NotFound.vue'),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { top: 0 };
    }
  },
});

router.beforeEach((to, from, next) => {
  const store = useMeStore();
  const { isAuth } = store;

  if (to.meta.requiresAuth === true && isAuth !== true) {
    next('/login');
  } else {
    document.title = `${to.name} - Fast Blockchain`;
    next();
  }
});

export default router;
