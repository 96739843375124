import { createApp } from 'vue';
import { createPinia } from 'pinia';
import Toast, { POSITION } from 'vue-toastification';
import App from './App.vue';
import router from './router';

import Card from '@/components/Card';
import Button from '@/components/Button';
import Loading from '@/components/Loading';

const app = createApp(App);
app.use(router);
app.use(createPinia());
app.use(Toast, { position: POSITION.TOP_RIGHT });
app
  .component('Card', Card)
  .component('Button', Button)
  .component('Loading', Loading);
app.mount('#app');

// app.config.errorHandler = (err, instance, info) => {
//   console.log('errorHandler', err, instance, info);
//   // report error to tracking services
// };
