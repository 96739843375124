import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';

var firebaseConfig = {
  apiKey: process.env.VUE_APP_FIREBASE_apiKey,
  authDomain: process.env.VUE_APP_FIREBASE_authDomain,
  projectId: process.env.VUE_APP_FIREBASE_projectId,
  storageBucket: process.env.VUE_APP_FIREBASE_storageBucket,
  messagingSenderId: process.env.VUE_APP_FIREBASE_messagingSenderId,
  appId: process.env.VUE_APP_FIREBASE_appId,
  measurementId: process.env.VUE_APP_FIREBASE_measurementId,
};

firebase.initializeApp(firebaseConfig);

const auth = firebase.auth();

async function checkAuthStatus() {
  return new Promise((resolve, reject) => {
    try {
      firebase.auth().onAuthStateChanged((user) => resolve(user));
    } catch {
      reject('api failed');
    }
  });
}

const getAuthHeader = async function () {
  await checkAuthStatus();
  if (auth.currentUser) {
    return { Authorization: `Bearer ${await auth.currentUser.getIdToken()}` };
  }
};

export { firebase, getAuthHeader, checkAuthStatus };
