import { defineStore } from 'pinia';
import { firebase, checkAuthStatus } from '@/api/firebase';
import { toast } from '@/lib/toast';
import { fetchjs } from '@/lib/fetchjs';
import router from '@/router';
import { useTierStore } from '@/stores/tier';

export const useMeStore = defineStore('me', {
  state: () => ({
    me: {
      /*
      id: String,
      email: String,
      firstName: String,
      lastName: String,
      token: String,
      countryId: '',
      tier: {},
      */
    },
    isLoading: false,
    isSaving: false,
    isAuth: false,
  }),
  actions: {
    set(me) {
      if (me.tier) {
        me.tier.theme = useTierStore().getTierTheme(me.tier);
      }

      this.me = me;
      this.isLoading = false;
      this.isSaving = false;
      this.isAuth = true;
    },

    async loginWithGoogle(goTo) {
      this.$reset();

      try {
        this.isLoading = true;
        const provider = new firebase.auth.GoogleAuthProvider();
        const result = await firebase.auth().signInWithPopup(provider);
        const uid = result.user.uid;

        const me = {
          _id: uid,
          email: result.additionalUserInfo.profile.email,
          firstName: result.additionalUserInfo.profile.given_name,
          lastName: result.additionalUserInfo.profile.family_name,
          picture: result.additionalUserInfo.profile.picture,
        };

        const profile = await fetchjs(
          `${process.env.VUE_APP_API_URL}/users/profile`,
          {
            method: 'PATCH',
            body: JSON.stringify(me),
          }
        );
        if (!profile) {
          return this.logout();
        }

        this.set(me);
        localStorage.setItem('uid', uid);
        this.checkLogin();

        router.push(goTo || '/');
      } catch (error) {
        toast(`Ops! Something went wrong`, 'error', error);
        this.logout();
      }
    },

    async checkLogin() {
      const uid = localStorage.getItem('uid');
      if (!uid) {
        return;
      }

      this.isAuth = true;
      this.isLoading = true;

      await checkAuthStatus();

      try {
        const me = await fetchjs(
          `${process.env.VUE_APP_API_URL}/users/profile`
        );
        if (!me._id) {
          return this.logout();
        }
        this.set(me);
      } catch (error) {
        this.logout();
      }
    },

    async logout() {
      try {
        await firebase.auth().signOut();
        localStorage.removeItem('uid');
        this.$reset();
        router.push('/login');
      } catch (error) {
        toast(`Ops! Something went wrong`, 'error', error);
      }
    },

    async updateProfile(me) {
      try {
        this.isSaving = true;
        me = { ...this.me, ...me };

        const json = await fetchjs(
          `${process.env.VUE_APP_API_URL}/users/profile`,
          {
            method: 'PATCH',
            body: JSON.stringify(me),
          }
        );
        this.set(json);

        toast(`Your profile has been updated`, 'success');
      } catch (error) {
        this.isSaving = false;
        toast(`Ops! Something went wrong`, 'error', error);
      }
    },

    async resetToken() {
      this.isSaving = true;

      try {
        const json = await fetchjs(
          `${process.env.VUE_APP_API_URL}/users/profile/resetToken`,
          {
            method: 'PATCH',
          }
        );

        if (json) {
          this.set(json);
          toast(`Your token has been updated`, 'success');
        }
      } catch (error) {
        toast(`Ops! Something went wrong`, 'error', error);
      } finally {
        this.isSaving = false;
      }
    },
  },
});
