import { defineStore } from 'pinia';
import urlSlug from 'url-slug';
import { toast } from '@/lib/toast';
import { fetchjs } from '@/lib/fetchjs';

export const useTierStore = defineStore('tier', {
  state: () => ({
    tiers: [],
    isLoading: false,
    priceAnnually: false,
  }),
  getters: {
    getBySlug: (state) => {
      return (slug) => state.tiers.find((item) => item.slug === slug);
    },
    getById: (state) => {
      return (id) => state.tiers.find((item) => item._id === id);
    },
    getNext: (state) => {
      return (price) =>
        state.tiers.find(
          (item) => !item.disabled && item.price.monthly > price
        );
    },
    getMin: (state) => {
      return () => {
        return state.tiers.reduce((prev, curr) => {
          return prev.price.monthly < curr.price.monthly ? prev : curr;
        });
      };
    },
    getMax: (state) => {
      return () => {
        return state.tiers.reduce((prev, curr) => {
          return prev.price.monthly > curr.price.monthly ? prev : curr;
        });
      };
    },
  },
  actions: {
    async fetchTiers() {
      if (this.tiers.length || this.isLoading) {
        return;
      }

      this.tiers = [];
      this.isLoading = true;

      try {
        const json = await fetchjs(
          `${process.env.VUE_APP_API_URL}/tiers?services=true`,
          {},
          false
        );
        json.results.forEach((item) => {
          this.tiers.push({
            ...item,
            ...{
              slug: urlSlug(item.name),
              theme: this.getTierTheme(item),
            },
          });
        });
      } catch (error) {
        toast(`Ops! Something went wrong`, 'error', error);
      } finally {
        this.isLoading = false;
      }
    },
    setPriceAnnually(value) {
      this.priceAnnually = value;
    },

    getTierTheme(tier) {
      if (tier.theme === 'free') {
        return 'success';
      } else if (tier.theme === 'startup') {
        return 'primary';
      } else if (tier.theme === 'business') {
        return 'danger';
      }
      return 'info';
    },
  },
});
