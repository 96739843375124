import { useToast } from 'vue-toastification';

const toast = function (message, theme = 'success', error = null) {
  const themes = ['success', 'error', 'default', 'info', 'warning'];
  if (!themes.includes(theme)) {
    console.error(`Wrong toast theme: ${theme}`);
  }

  if (process.env.NODE_ENV === 'development' && error) {
    console.error(error);
  }
  useToast()(message, { type: theme });
};

export { toast };
