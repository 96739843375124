<template>
  <a v-if="href" :href="href" target="_blank">
    <component :is="icon" />
    {{ name }}
    <OpenInNew />
  </a>
  <router-link v-else :to="to" :key="key" @click="click">
    <component :is="icon" />
    {{ name }}
  </router-link>
</template>

<script>
import OpenInNew from 'vue-material-design-icons/OpenInNew.vue';

export default {
  name: 'Link',
  props: {
    name: String,
    to: String,
    href: String,
    icon: Object,
    click: {
      type: Function,
      default: () => {},
    },
  },
  components: {
    OpenInNew,
  },
};
</script>
