<template>
  <footer>
    <Logo theme="dark" :isoLogo="true" />
    <nav>
      <Link
        href="https://www.fastblockchains.com/privacy.html"
        name="Privacy & Policy"
      />
      <Link href="https://www.fastblockchains.com/terms.html" name="Terms" />
    </nav>
  </footer>
</template>

<script>
import Logo from '@/components/Logo';
import Link from '@/components/Link';

export default {
  name: 'Footer',
  components: { Logo, Link },
};
</script>

<style scoped lang="scss">
footer {
  display: flex;
  align-items: center;
  justify-content: center;
  align-items: stretch;
  background: rgba(0, 0, 0, 0.1);
  padding: $padding $space;
  margin: auto 0 0 0;
  border-top: 1px $colorBorder solid;
  .logo {
    height: $space * 2;
  }
  nav {
    display: grid;
    grid-auto-flow: column;
    gap: $padding * 2;
    margin: 0 0 0 auto;
    a {
      display: flex;
      gap: $padding;
      align-items: center;
      justify-content: center;
      font-size: small;
      font-weight: 900;
      color: $colorPrimary;
      &.router-link-exact-active {
        color: $colorActive;
      }
      :deep(.material-design-icon) {
        display: flex;
        &.open-in-new-icon {
          width: 1rem;
        }
      }
    }
  }
}
</style>
