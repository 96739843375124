<template>
  <router-link to="/" class="logo">
    <img :src="this.getPicture" />
  </router-link>
</template>

<script>
export default {
  props: {
    theme: {
      type: String,
      default: 'dark',
      validator(value) {
        return ['dark', 'light'].includes(value);
      },
    },
    isoLogo: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    getPicture() {
      return require(`@/assets/${this.isoLogo ? 'isologo' : 'logo'}-${
        this.theme
      }.svg`);
    },
  },
};
</script>

<style scoped lang="scss">
.logo {
  position: relative;
  padding: 0;
  border-radius: $padding;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: $padding;
  height: $buttonHeight - ($padding * 2);
  img {
    height: 100%;
  }
}
</style>
