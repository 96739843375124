<template>
  <div
    :class="{ dropdown: true, isVisible, showArrow }"
    v-click-out-side="hide"
  >
    <Button :click="toogle" theme="info" title="menu" :isLoading="isLoading">
      <slot />
      <ArrowDropDown class="arrow" v-if="showArrow" />
    </Button>
    <Card is="ul">
      <li
        v-for="(item, key) in items"
        :key="key"
        @click="
          hide();
          item.click();
        "
      >
        {{ item.name }}
      </li>
    </Card>
  </div>
</template>

<script>
import clickOutSide from '@mahdikhashan/vue3-click-outside';
import ArrowDropDown from 'vue-material-design-icons/ChevronDown.vue';

export default {
  name: 'Dropdown',
  props: {
    items: Array,
    showArrow: {
      type: Boolean,
      default: true,
    },
    isLoading: Boolean,
  },
  data() {
    return {
      isVisible: false,
    };
  },
  methods: {
    toogle() {
      this.isVisible = !this.isVisible;
    },
    hide() {
      this.isVisible = false;
    },
  },
  directives: { clickOutSide },
  components: { ArrowDropDown },
};
</script>

<style scoped lang="scss">
.dropdown {
  position: relative;
  Button {
    width: 100%;
    .material-design-icon.arrow {
      transition: $duration transform;
    }
  }
  ul.card {
    padding: 0;
    gap: 0;
    position: absolute;
    top: 100%;
    right: 0;
    width: 100%;
    min-width: 200px;
    background: white;
    z-index: 1;
    pointer-events: none;
    opacity: 0;
    overflow: hidden;
    transition: $duration opacity;
    li {
      padding: $space;
      width: 100%;
      cursor: pointer;
      transition: $duration background-color;
      &:hover {
        background-color: #f3f3f3;
      }
    }
  }
  &.showArrow Button {
    justify-content: space-between;
    text-align: start;
  }
  &.isVisible {
    ul.card {
      pointer-events: all;
      opacity: 1;
    }
    Button .material-design-icon.arrow {
      transform: rotate(180deg);
    }
  }
}
</style>
